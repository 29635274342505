import {useTranslation} from "react-i18next";


function HeaderComponent(props) {

    const { t, i18n } = useTranslation();

    var _langClass = 'fw-bold';

    const _setLanguage = (lang) => {
        i18n.changeLanguage(lang)
        if(lang === 'ua') {
            document.getElementById('langUA').classList.add(_langClass);
            document.getElementById('langEN').classList.remove(_langClass);
        }else {
            document.getElementById('langUA').classList.remove(_langClass);
            document.getElementById('langEN').classList.add(_langClass);
        }
    }

    const _ua = <small id={'langUA'} onClick={() => _setLanguage('ua') }>UA</small>
    const _en = <small id={'langEN'} onClick={() => _setLanguage('en')}>EN</small>

    return (
        <header className={'container fixed-top'}>
            {/*<div className="d-flex justify-content-between align-items-center">*/}

            {/*    <div className={'contentMenu bg-dark text-white p-1 shadow rounded-bottom pb-2 d-flex1'}>*/}

            {/*        {_ua} | {_en}*/}
            {/*        <ul className="d-none">*/}
            {/*            <li><a className="dropdown-item" href="#">About Me</a></li>*/}
            {/*            <li><a className="dropdown-item" href="#">Resume</a></li>*/}
            {/*            <li><a className="dropdown-item" href="#">Certificates</a></li>*/}
            {/*            <li><a className="dropdown-item" href="#">Media</a></li>*/}
            {/*            <li><a className="dropdown-item" href="#">Contacts</a></li>*/}
            {/*        </ul>*/}
            {/*        /!*<div className="dropdown1">*!/*/}
            {/*        /!*    <div className="dropdown-toggle" type="button" id="dropdownMenuButton1"*!/*/}
            {/*        /!*            data-bs-toggle="dropdown" aria-expanded="false">*!/*/}
            {/*        /!*    </div>*!/*/}
            {/*        /!*    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">*!/*/}
            {/*        /!*        <li><a className="dropdown-item" href="#">About Me</a></li>*!/*/}
            {/*        /!*        <li><a className="dropdown-item" href="#">Resume</a></li>*!/*/}
            {/*        /!*        <li><a className="dropdown-item" href="#">Certificates</a></li>*!/*/}
            {/*        /!*        /!*<li><a className="dropdown-item" href="#">Work/Portfolio</a></li>*!/*!/*/}
            {/*        /!*        /!*<li><a className="dropdown-item" href="#">Science work</a></li>*!/*!/*/}
            {/*        /!*        <li><a className="dropdown-item" href="#">Media</a></li>*!/*/}
            {/*        /!*        <li><a className="dropdown-item" href="#">Contacts</a></li>*!/*/}
            {/*        /!*    </ul>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*        <small className="text-secondary">*/}

            {/*        </small>*/}
            {/*    </div>*/}

            {/*</div>*/}
        </header>
    );
}

export default HeaderComponent;