import {useEffect, useState} from "react";

function ScienceWorkPage() {


    const _url = '/files/science-work.json';

    const [data, setData] = useState([]);

    const dataYears = [2024, 2023, 2022, 2021, 2020, 2019]

    const [filterYear, setFilterYear] = useState(0);

    useEffect(() => {


        fetch(_url)
            .then((res) => res.json())
            .then((dat) => {
                setData(dat);
            })
    })

    const handleFilter = (value) => {
        setFilterYear(value)
    }

    return <div className={'container min-vh-100'}>


        <div className="col-md-12 col-12 text-start mt-5">
            <h1 className={'display-1'}>Science Work</h1>
            <small className="text-secondary">
                Just a quick glimpse.
            </small>
        </div>

        <div className="d-flex flex-wrap">
            <div className="col-md-">
                <div className={'pt-2 p-1'}>
                    <button className="btn bg-secondary-subtle p-2 rounded" onClick={() => handleFilter(0)}>
                        <small> All </small>
                    </button>
                </div>
            </div>
            {
                dataYears.map((year, i) => <div className="col-md-">
                    <div className={'pt-2 p-1'}>
                        <button className="btn bg-secondary-subtle p-2 rounded" onClick={() => handleFilter(year)}>
                           <small> {year}</small>
                        </button>
                    </div>
                </div>)
            }


        </div>

        {
            data.length > 0 && data.filter((a) => filterYear != 0 ? a.year == filterYear : true).map((item, i) => <div id={i} className="row mt-5 align-items-center">
                <div className="col-md-1 text-end">
                    <small className={'text-secondary'}>{item.year}</small>
                </div>
                <div className="col-md-9">
                    <small className="text-secondary">
                        {item.type}
                    </small>
                    <h4>
                        {
                            item.title
                        }
                    </h4>
                </div>
                <div className="col-md-2">
                    <a href={item.link} target={'_blank'} className={'text-decoration-none text-secondary d-flex align-items-center'}>
                        <small className={'m-1'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-download" viewBox="0 0 16 16">
                                <path
                                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                <path
                                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                            </svg> download</small>
                        |
                        <small className={'m-1'}> read  <svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                        <path fill-rule="evenodd"
                              d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                    </svg></small>

                    </a>
                </div>
            </div>)
        }


    </div>
}

export default ScienceWorkPage;