function NoPage() {

    return <div className={'pt-5'}>

        <div className={'col-md-8 col-12 m-auto p-5 mt-5'}>
            <h1>
                Page not found!
            </h1>
            <p className={'p-5'}>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt dignissimos eum eveniet iusto molestiae quasi rerum tempora velit veniam. Asperiores delectus distinctio facilis, illum molestias perspiciatis porro quisquam sit totam.
            </p>
            <a href="/" className={'btn btn-dark rounded-pill p-3 col-md-4 col-12 m-auto'}>
                Go Back
            </a>
        </div>

    </div>
}

export default NoPage;