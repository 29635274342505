import React from 'react';
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import { Outlet } from "react-router-dom";

function PageTemplate(props) {

    return (
        <>
            <HeaderComponent />

            <main className={'min-vh-100'}>
                <Outlet />
            </main>

            <FooterComponent />
        </>
    );
}

export default PageTemplate;